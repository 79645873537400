import * as React from 'react'
import { HeadFC } from 'gatsby'
import PageTemplate from '../components/templates/PageTemplate'
import HelpTemplate from '../components/organisms/HelpTemplate'
import DefaultHelmet from '../components/atoms/DefaultHelmet'

const NotFoundPage = () => {
  return (
    <PageTemplate>
      <HelpTemplate />
    </PageTemplate>
  )
}

export default NotFoundPage

export const Head: HeadFC = () => (
  <>
    <title>Ayudar - Comic Colombiano</title>
    <meta
      name="description"
      content="¡Novelas gráficas, revistillas, webcómics y más! Conoce aquí el catálogo más grande de cómic colombiano y sus autores. ¡La inscripción es gratuita!"
    />
    <meta
      name="keywords"
      content="colombiano,comic,base,de,datos,comics,webcomics,webcomic,novela,grafica,arte,revistillas,catalogo"
    />
    <DefaultHelmet />
  </>
)
