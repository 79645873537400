import React from 'react'
import { navigate } from 'gatsby'

// GraphQl
import { getGraphComics } from '../../../graphQL'

// Model
import { ComicModel } from '../../../models'

// Utils
import { createPath } from '../../../utils/utils'

// Styles
import { helpTitle, helpSignature, helpLink } from './style.module.scss'

const HelpTemplate: React.FC = () => {
  const allComics = getGraphComics()

  const randomPage = (store?: boolean) => {
    let random:ComicModel
    if (store) {
      const buyable = allComics.filter((comic)=> comic.shop)
      random = buyable[Math.floor(Math.random() * buyable.length)]
    } else {
      random = allComics[Math.floor(Math.random() * allComics.length)]
    }
    navigate(`/comic/${random.id}-${createPath(random.name)}`)
  }

  return (
    <>
      <h1 className={helpTitle}>Quiero ayudar</h1>
      <h2>¿Te gusta nuestro proyecto y quieres ayudarnos?</h2>
      <p>
        Nuestra base datos y la página web existe como un apoyo para los
        creadores de comics de toda Colombia. Por lo cual la mejor forma de
        ayudarnos es apoyando directamente a los artistas. Por eso si te es
        posible trata de{' '}
        <span className={helpLink} onClick={() => {randomPage(true)}}>
          comprar un cómic
        </span>{' '}
        o si no{' '}
        <span className={helpLink} onClick={() => {randomPage()}}>
          lee el comic
        </span>{' '}
        de algún artista que no hayas leído antes.
      </p>
      <p>
        Al darle clic a cualquiera de estos enlaces te enviaremos a la página de
        alguno de nuestros comics al azar. Así puedes encontrar una historia
        nueva que puedas disfrutar y compartir.
      </p>
      <p>
        Finalmente, si quieres contactarte con nosotros puedes enviar un correo
        a info@dilancovak.com y con gusto te responderemos
      </p>
      <h3 className={helpTitle}>Gracias por apoyarnos</h3>
      <h4 className={helpSignature}>El equipo de comiccolombiano.com</h4>
    </>
  )
}

export default HelpTemplate
